import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Art1 from "../../images/art-1.jpg"
import Art2 from "../../images/art-2.jpg"
import Art3 from "../../images/art-3.jpg"
import Art4 from "../../images/art-4.jpg"
import Art5 from "../../images/art-5.jpg"
import Art6 from "../../images/art-6.jpg"
import Kesem0 from "../../images/kesem-0.jpg"
import Kesem1 from "../../images/kesem-1.jpg"
import Kesem2 from "../../images/kesem-2.jpg"
import Kesem3 from "../../images/kesem-3.jpg"
import Kesem4 from "../../images/kesem-4.png"

export default function Art() {
    const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "art-1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO title="art" />
            <div className="c12 title main">
                <h1>Art</h1>
            </div>
            <div className="c12 main">
                <div className="summary">
                    <div className="summary-section">
                        <div className="section summary-item">
                            <h5 className="subtitle">Overview</h5>
                            <p>A sample of different projects I've worked on.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid">
                <div className="c6">
                    <div className="summary-section">
                        <div className="section summary-item">
                            <h5 className="subtitle">Painting & Ceramics</h5>
                            <Img fluid={data.one.childImageSharp.fluid} className="img" style={{ width: "100%" }}/>
                            <p>I formed the ceramic plate and painted the glaze zebra (2010).</p>
                        </div>
                        <br/>
                        <div className="section summary-item">
                            <h5 className="subtitle">Crocheting</h5>
                            <div className="portfolio-content">
                                <div>
                                    <img src={Art2} className="img"/>
                                </div>
                                <div>
                                    <img src={Art3} className="img"/>
                                </div>
                            </div>
                            <p>I crocheted this bowl and rug from T-shirt yarn (2017).</p>
                        </div>
                        <br/>
                        <div className="section summary-item">
                            <h5 className="subtitle">Jewelry</h5>
                            <div className="portfolio-content">
                                <div>
                                    <img src={Kesem2} className="img"/>
                                </div>
                                <div>
                                    <img src={Kesem1} className="img"/>
                                </div>
                            </div>
                            <p>I had a jewelry business where I partnered with a local Tel Aviv artist to sell her designs in the US and also created my own pieces to sell. These are a few of the pieces I created (2012).</p>
                            <div className="portfolio-content">
                                <div>
                                    <img src={Kesem0} className="img"/>
                                </div>
                                <div>
                                    <br/>
                                    <p>I cut strips of elastic, hand-dyed them, tied and finished the edges. I did this while at a weeklong family camp and employed my family to help me tie each one. I ended up making over 1,500 hair ties.</p>
                                </div>
                            </div>
                            <div className="portfolio-content">
                                <div>
                                    <img src={Kesem4} className="img"/>
                                </div>
                                <div>
                                    <br/>
                                    <p>I also handmade friendship bracelets and put a grungy spin on them by adding spiked studs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="section summary-item">
                            <h5 className="subtitle">Mask Chains (Jewelry)</h5>
                            <div className="portfolio-content">
                                <div>
                                    <img src={Art5} className="img"/>
                                </div>
                                <div>
                                    <img src={Art6} className="img"/>
                                </div>
                            </div>
                            <p>During the COVID-19 pandemic, I made mask chains that I sent to friends and family around the world. Here are two examples of chains that I created (2020).</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-nav">
                <Link to="/portfolio/what-the-tech">What the Tech</Link>
                <Link to="/portfolio/hackathon">Hack for Olim Hackathon</Link>
                <Link to="/portfolio/kfitzah">Kfitzah</Link>
                <Link to="/portfolio/design-thinking">Design Workshops & Consulting</Link>
                <Link to="/">Home</Link>
            </div>
        </Layout>
    )
}